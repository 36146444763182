<template>
  <v-container class="py-6">
    <v-row>
      <v-col cols="12">
        <div class="box-wrapper">
          <div
            class="box-overlay"
            :class="{ open: isSidebar }"
            @click="isSidebar = !isSidebar"
          ></div>
          <div class="box-sidebar pb-3 shadow-sm" :class="{ open: isSidebar }">
            <DashbordSidebarTwo />
          </div>

          <v-card class="ml-2" elevation="0">
            <v-row>
              <v-card-title>
                <v-breadcrumbs
                  class="text-uppercase font-weight-bolder"
                  :items="itemsLink"
                />
              </v-card-title>

              <v-card-text>
                <v-row>
                  <v-col cols="12" class="ml-4">
                    <h4>MEUS CARTÕES</h4>
                  </v-col>
                  <v-col cols="12">
                    <v-divider />
                  </v-col>
                </v-row>
              </v-card-text>
            </v-row>

            <v-col cols="12" md="12">
              <v-card-text>
                <v-row class="justfy-center">
                  <v-col cols="12" md="6" v-for="index in 5" :key="index++">
                    <v-hover v-slot:default="{ hover }" open-delay="200">
                      <v-card
                        :elevation="hover ? 16 : 2"
                        class="mx-auto"
                        max-width="280"
                        style="border-radius: 22px"
                      >
                        <div class="text-center">
                          <v-icon color="secondary" size="120px"
                            >mdi-credit-card</v-icon
                          >
                        </div>

                        <div
                          class="primary--text text-uppercase text-center font-weight-bold"
                        >
                          CARTÃO
                        </div>
                        <v-card-text>
                          <v-row align="center" class="mx-0"> </v-row>
                        </v-card-text>
                        <v-divider class="mx-4" />
                      </v-card>
                    </v-hover>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-col>
          </v-card>
        </div>
      </v-col>
    </v-row>

    <v-dialog
      name="dialog-ver-proforma"
      v-model="dialogVerProforma"
      width="600"
      transition="dialog-top-transition"
    >
      <v-card>
        <v-divider />
        <v-card-text>
          <div class="text-center">
            <v-icon color="warning" size="100px">
              mdi-information-outline
            </v-icon>
          </div>
          <v-row>
            <v-col cols="12" class="text-center text-h6">
              Fatura Proforma gerada com sucesso! Veja a aba a tua direita para
              imprimir.
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { BASE_URL } from "@/configs/api";
import axios from "axios";

import DashbordSidebarTwo from "@/components/DashboardSidebarTwo.vue";
export default {
  components: {
    DashbordSidebarTwo,
  },
  data() {
    return {
      dialogVerProforma: false,
      produto: {},
      dialogAvaliarProduto: false,
      dialogFaturaDetalhes: false,
      item: {
        pagamento_vendas_online_items: [],
      },
      fatura: {
        pagamento_vendas_online_items: [],
      },
      pagamento_vendas_online_items: [],
      itemsLink: [
        {
          text: "Início",
          disabled: false,
          href: "/portal/pages/cliente/dashboard",
        },
        {
          text: "MEUS CARTÕES",
          disabled: true,
          href: "/",
        },
      ],
      pagamento: {},
      pagamentos: {
        pagamento_vendas_online_items: [],
      },
      dialogProdutosFatura: false,
      talPedidos: 0,
      query: {},
      pedidos: [
        {
          id: 1,
          faturaReference: "12FGH3",
          created_at: "06-04-2023",
          estado_factura: {
            id: 1,
            designacao: "ENTREGUE",
          },
          valor_a_pagar: "955",
        },
        {
          id: 2,
          faturaReference: "21FYH3",
          created_at: "13-04-2023",
          estado_factura: {
            id: 2,
            designacao: "A CAMINHO",
          },
          valor_a_pagar: "55459",
        },
      ],
      headers: [
        {
          text: "Código",
          align: "start",
          sortable: true,
          value: "id",
        },
        {
          text: "Produto",
          value: "pagamento_vendas_online_items.nomeProduto",
        },
        {
          text: "Qty",
          value: "pagamento_vendas_online_items[0].quantidade",
        },
        {
          text: "Valor Pago",
          value: "totalPagamento",
        },
        {
          text: "Data",
          value: "dataPagamentoBanco",
        },
        {
          text: "Acções",
          value: "actions",
          sortable: false,
        },
      ],
      overlay: false,
      factura: {},
      factura_detalhes: {},
      pagamentos_facturas: [],
      facturasItems: [],
      search: "",
      loading: false,
      sortBy: "created_at",
      sortDesc: true,
      overlay: false,
      isSidebar: false,
      page: 1,
      itemsPerPage: 5,
      items: [
        0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 14, 15, 16, 17, 18, 19, 20,
      ],
      range: [0, 100, 500, 1000],
      itemsTwo: ["Low to High", "High to Low", "Date"],
    };
  },
  computed: {},
  created() {
    window.addEventListener("scroll", this.handleScroll);
    this.getClientePagamentos();
    this.getCartoes();
  },
  mounted() {
    this.checkUserIsAuth();
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    // /api/empresa/imprimirExtratoCartaoCliente/34
    checkUserIsAuth() {
      if (sessionStorage.getItem("TOKEN") == null) {
        this.$router.push("/");
      }
    },
    getCartoes() {
      this.overlay = true;
      var TOKEN_SESSAO = sessionStorage.getItem("TOKEN");
      const url = `${BASE_URL}/empresa/imprimirExtratoCartaoCliente/34`;
      axios
        .get(url, {
          headers: {
            Authorization: "Bearer " + TOKEN_SESSAO,
          },
        })
        .then((response) => {
          // this.pagamentos = response.data.data;
          alert(JSON.stringify(response.data));
        });
    },
    printComprovativo(comprovativo) {
      window.open(comprovativo);
    },
    setAvaliarProduto() {
      let produto = this.produto;
      // alert(JSON.stringify(produto))
      console.log(produto);
    },
    async printFatura(fatura) {
      // /api/empresa/imprimirExtratoCartaoCliente/34
      let urlJasper = `${BASE_URL}/pagamentosVendaOnline/imprimir/${fatura}`;
      var token = sessionStorage.getItem("TOKEN");
      try {
        let response = await axios.get(urlJasper, {
          responseType: "arraybuffer",
          headers: {
            Authorization: `Bearer ${token}`, // Exemplo de cabeçalho de autorização
          },
        });
        if (response.status === 200) {
          var file = new Blob([response.data], {
            type: "application/pdf",
          });
          var fileURL = URL.createObjectURL(file);
          window.open(fileURL);
          this.dialogVerProforma = false;
        }
      } catch (error) {
        console.log("Erro ao carregar pdf");
        this.dialogVerProforma = false;
      }
    },

    showDialogFaturaDetalhes(item) {
      this.fatura = item;
      this.pagamento_vendas_online_items = item.pagamento_vendas_online_items;
      this.dialogFaturaDetalhes = true;
    },
    showDialogProdutosFatura(item) {
      this.fatura = item;
      this.pagamento_vendas_online_items = item.pagamento_vendas_online_items;
      this.dialogProdutosFatura = true;
    },
    formatePrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    getClientePagamentos() {
      this.overlay = true;
      var TOKEN_SESSAO = sessionStorage.getItem("TOKEN");
      const url = `${BASE_URL}/listarTodosPagamentosVendaOnlineUserAutenticado`;
      axios
        .get(url, {
          headers: {
            Authorization: "Bearer " + TOKEN_SESSAO,
          },
        })
        .then((response) => {
          this.pagamentos = response.data.data;
          // this.pagamento_vendas_online_items =
          //   this.pagamento.pagamento_vendas_online_items;
          // alert(JSON.stringify(this.pagamentos));
          // console.log(this.pagamentos);
          // setTimeout(() => {
          //   this.overlay = false;
          // }, 1000);
        });
    },

    showPedidoDetalhes(item) {
      this.pagamento = item;
      this.dialogProdutosFatura = true;
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
  },
};
</script>
